import { type IReportsPopup, ReportsPopupType } from '@/interfaces';

export const groupReportsPopupsByType = (data: any) => {
  if (!data?.length) return null;

  return data.reduce(
    (acc: any, item: any) => {
      const key = item.pageType || ReportsPopupType.homePage;
      return { ...acc, [key]: [...(acc[key] || []), item] };
    },
    <{ [pageType in ReportsPopupType]: IReportsPopup[] }>{},
  );
};
