import type { IPmAnalyticsQuery } from './pmAnalytics.interfaces';
import type { IRegistrationWall } from './registrationWall.interfaces';

export enum LineChartType {
  ONE_LINE = 'One Line',
  MULTIPLE_LINES = 'Multiple Lines',
}

export enum LineTitleType {
  Carrier = 'Carrier',
  Country = 'Country',
  Year = 'Year',
  Tradelane = 'Tradelane',
}

export interface IChartCarrierInfo {
  carrierName?: string;
  carrierLogo?: string;
}

export interface ICarrierName {
  logo?: string;
  name?: string;
}

// TODO-after-24: Interface/Enum based on table-name for easier memorization and distinction
export interface IChartData {
  transitTimePreviousQuarter?: string;
  transitTimeGrowth?: string;
  transitTime?: string;
  tradelane?: string;
  parcelVolume?: number;
  pParcelToCountryIso?: string;
  pParcelFromCountryIso?: string;
  onTimeRatio?: number;
  onTime?: number;
  issues?: number;
  issueRatio?: number;
  firstAttemptSuccessRatio?: number;
  firstAttemptSuccess?: number;
  deliveredEddAvailable?: number;
  delivered?: number;
  name?: string;
  cCarrierId?: string;
  logoLink?: string;
  timeframe?: string;
  quotedTt?: string;
  lowerTransitTime?: number;
  upperTransitTime?: number;
  isTtQualified?: boolean;
  // Market table
  regionOrigin?: string;
  regionDestination?: string;
  countryOrigin?: string;
  countryOriginName?: string;
  countryDestination?: string;
  countryDestinationName?: string;
  tradelaneName?: string;
  quarter?: string;
  carrierName?: string;
  carrierId?: string;
  carrierLogo?: string;
  revenue?: number;
  year?: string;
  unit?: string;
  // Tradelane table
  carrierHeadquarter?: string;
  carrierServiceType?: string;
  carrierCost?: number;
  // Retailer table
  orderVolumeChange?: number;
  orderVolume?: number;
  month?: string;
  retailerName?: string;
  retailerSlug?: string;
}

export interface IChart {
  title: string;
  showDescription: boolean;
  type: ChartType;
  minimumRecord: number | null;
  query: IPmAnalyticsQuery;
  registrationWall: IRegistrationWall;
  data?: Record<string, any>[] | null | IChartData[];
}

export enum ChartType {
  TableMetrics = 'Table metrics',
  LineMetrics = 'Line metrics',
  BarMetrics = 'Bar metrics',
  TrendMetrics = 'Trend metrics',
}

export interface IModifiedChartDataToLineMetrics {
  header: {
    pParcelToCountryIso?: string;
    pParcelFromCountryIso?: string;
    name?: string;
    cCarrierId?: string;
    logoLink?: string;
    tradelane?: string;
    // Market table
    regionOrigin?: string;
    regionDestination?: string;
    countryOrigin?: string;
    countryOriginName?: string;
    countryDestination?: string;
    countryDestinationName?: string;
    tradelaneName?: string;
    carrierName?: string;
    carrierId?: string;
    carrierLogo?: string;
    year?: string;
    unit?: string;
  };
  body: {
    transitTimePreviousQuarter?: string;
    transitTimeGrowth?: string;
    transitTime?: string;
    parcelVolume?: number;
    onTimeRatio?: number;
    onTime?: number;
    issues?: number;
    firstAttemptSuccessRatio?: number;
    firstAttemptSuccess?: number;
    deliveredEddAvailable?: number;
    delivered?: number;
    // Market table
    revenue?: number;
    // Tradelane table
    carrierCost?: number;
  };
}

export enum ChartPercentageKeys {
  firstAttemptSuccessRatio = 'firstAttemptSuccessRatio',
  orderVolumeChange = 'orderVolumeChange',
  issueRatio = 'issueRatio',
  onTimeRatio = 'onTimeRatio',
}

export enum TimeKey {
  year = 'year', // 2024
  month = 'month', // 2024-01-31 23:59:59 --> Jan, 2024
  quarter = 'quarter', // Q1 2024
  timeframe = 'timeframe', // Q1 2024
}

// Source: https://git.parcelperform.com/dev-team/pm_analytics
export enum KeysCanBeCompared {
  transitTimePreviousQuarter = 'transitTimePreviousQuarter',
  transitTimeGrowth = 'transitTimeGrowth',
  transitTime = 'transitTime',
  tradelane = 'tradelane',
  parcelVolume = 'parcelVolume',
  pParcelToCountryIso = 'pParcelToCountryIso',
  pParcelFromCountryIso = 'pParcelFromCountryIso',
  onTimeRatio = 'onTimeRatio',
  onTime = 'onTime',
  issues = 'issues',
  issueRatio = 'issueRatio',
  firstAttemptSuccessRatio = 'firstAttemptSuccessRatio',
  firstAttemptSuccess = 'firstAttemptSuccess',
  deliveredEddAvailable = 'deliveredEddAvailable',
  delivered = 'delivered',
  name = 'name',
  cCarrierId = 'cCarrierId',
  logoLink = 'logoLink',
  timeframe = 'timeframe',
  quotedTt = 'quotedTt',
  lowerTransitTime = 'lowerTransitTime',
  upperTransitTime = 'upperTransitTime',
  isTtQualified = 'isTtQualified',
  // Market table
  regionOrigin = 'regionOrigin',
  regionDestination = 'regionDestination',
  countryOrigin = 'countryOrigin',
  countryOriginName = 'countryOriginName',
  countryDestination = 'countryDestination',
  countryDestinationName = 'countryDestinationName',
  tradelaneName = 'tradelaneName',
  quarter = 'quarter',
  carrierName = 'carrierName',
  carrierId = 'carrierId',
  carrierLogo = 'carrierLogo',
  revenue = 'revenue',
  year = 'year',
  unit = 'unit',
  region = 'region', // only at server side
  country = 'country', // only at server side
  // Tradelane table
  carrierHeadquarter = 'carrierHeadquarter',
  carrierServiceType = 'carrierServiceType',
  carrierCost = 'carrierCost',
  // Retailer table
  month = 'month',
  orderVolume = 'orderVolume',
  orderVolumeChange = 'orderVolumeChange',
  retailerSlug = 'retailerSlug',
  retailerName = 'retailerName',
}

export enum CustomQueryValues {
  countryCode = 'countryCode',
  marketName = 'marketName',
  previousQuarter = 'previousQuarter',
}
