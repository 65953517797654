import DOMPurify from 'dompurify';
import { type ReactNode, createElement } from 'react';

// Import JSDOM dynamically only on the server side
let JSDOM: any;
if (typeof window === 'undefined') {
  JSDOM = require('jsdom').JSDOM;
}

const renderHTML = (rawHTML: string, element = 'div'): ReactNode => {
  // Check if we're in a browser environment
  if (typeof window === 'undefined') {
    return _handleServerSideSanitization(rawHTML, element);
  }

  return _handleClientSideSanitization(rawHTML, element);
};

const _handleServerSideSanitization = (rawHTML: string, element = 'div') => {
  const jsdom = new JSDOM('');

  // Create a DOMPurify instance using the JSDOM window
  const serverDOMPurify = DOMPurify(jsdom.window);
  const sanitizedHTML = serverDOMPurify.sanitize(rawHTML);

  return createElement(element, {
    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
    dangerouslySetInnerHTML: { __html: sanitizedHTML },
  });
};

const _handleClientSideSanitization = (rawHTML: string, element = 'div') => {
  const sanitizedHTML = DOMPurify.sanitize(rawHTML);

  return createElement(element, {
    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
    dangerouslySetInnerHTML: { __html: sanitizedHTML },
  });
};

export default renderHTML;
